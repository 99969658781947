import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';
import { typeComplete, typeFail, typePending } from 'state-domains/utils';
import {
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_EXPORT_TEMPLATES,
    CLEAR_EXPORT_TEMPLATES_ACTION_STATE,
    CLONE_EXPORT_TEMPLATES,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE,
    DELETE_EXPORT_TEMPLATES,
    EXPORT_TEMPLATES,
    SAVE_EXPORT_TEMPLATES,
} from 'state-domains/types/actionTypes';
import { CloneExportTemplatePayload, ExportTemplate } from 'state-domains/domain/subscription';
import { loadHelper } from 'state-domains/domain/utils';
import { DATATABLE_TYPE, OperatorNames } from 'state-domains/constants';
import { GetState } from 'state-domains/types/state';
import { UserPortalState } from 'src/state';
import { dispatchUpdateSelectClear } from 'state-domains/domain/subscription/state/actions';

import { dataShim } from '../shim';
import { ExportDestination } from '../types';

const loadExportTemplates =
    (searchTerm = '') =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(EXPORT_TEMPLATES) });
        const { filters } = loadHelper(
            DATATABLE_TYPE.EXPORT_TEMPLATES,
            searchTerm,
            getState,
            'created_on',
        );

        const modifiedFilters = filters.filter(
            (x) => !['selected_tables', 'selected_headers'].includes(x.field),
        );

        dataShim.loadExportTemplates(modifiedFilters).subscribe({
            next: (templates: { ExportTemplate: ExportTemplate[] }) => {
                const headerFilter = filters.find((x) => x.field === 'selected_headers');
                const tableFilter = filters.find((x) => x.field === 'selected_tables');

                const filteredTemplates = templates.ExportTemplate.filter((x) => {
                    let result = true;

                    [
                        ...(headerFilter ? [headerFilter] : []),
                        ...(tableFilter ? [tableFilter] : []),
                    ].forEach((y) => {
                        if (result) {
                            const ids = x.filesToExport.reduce((accum, value) => {
                                const key =
                                    y.field === 'selected_headers' ? 'headerId' : 'tableViewId';
                                value.tableIds.forEach((y) =>
                                    accum.push(...(y[key] ? [y[key]] : [])),
                                );

                                return accum;
                            }, [] as string[]);

                            switch (y.operator) {
                                case OperatorNames.ONE_OF:
                                    if (!y.values.length) {
                                        result = !ids.length;
                                    } else if (y.values[0] === 'all') {
                                        result = !!ids.length;
                                    } else {
                                        result = y.values.some((x: string) => ids.includes(x));
                                    }
                                    break;
                                case OperatorNames.HAS_VALUES:
                                    result = !!ids.length;
                                    break;
                                case OperatorNames.EMPTY:
                                    result = !ids.length;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });

                    return result;
                });

                dispatch({
                    type: typeComplete(EXPORT_TEMPLATES),
                    payload: filteredTemplates,
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(EXPORT_TEMPLATES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearExportTemplates = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_EXPORT_TEMPLATES });
};

const cloneExportTemplates = (payload: CloneExportTemplatePayload) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(CLONE_EXPORT_TEMPLATES) });
    dataShim.cloneExportTemplate(payload).subscribe({
        next: (response: ExportTemplate) => {
            dispatch({
                type: typeComplete(CLONE_EXPORT_TEMPLATES),
                payload: response.id,
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: {
                    id: response.id,
                    item: response,
                    collectionName: 'exportTemplates',
                    type: 'POST',
                },
            });
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(CLONE_EXPORT_TEMPLATES), payload: { error } });
        },
    });
};

const clearExportTemplatesActionState = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_EXPORT_TEMPLATES_ACTION_STATE });
};

const deleteExportTemplates = (id: string) => (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: typePending(DELETE_EXPORT_TEMPLATES) });
    dataShim.deleteExportTemplates(id).subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(DELETE_EXPORT_TEMPLATES), payload: response });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: { id, item: { id }, collectionName: 'exportTemplates', type: 'DELETE' },
            });
            dispatchUpdateSelectClear([{ id }], getState() as UserPortalState, dispatch, true);
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(DELETE_EXPORT_TEMPLATES), payload: { error } });
        },
    });
};

const saveExportTemplate =
    (payload: Partial<ExportTemplate>, showSuccessSnackbar?: boolean, navigate?: Function) =>
    (dispatch: Dispatch) => {
        const { goose, ...rest } = payload;

        // If evo isnt present, remove goose fields.
        const newPayload = !payload.destination?.includes(ExportDestination.EVO) ? rest : payload;

        dispatch({ type: typePending(SAVE_EXPORT_TEMPLATES) });
        dataShim.saveExportTemplate(newPayload).subscribe({
            next: (response: ExportTemplate) => {
                const id = response.id;
                dispatch({ type: typeComplete(SAVE_EXPORT_TEMPLATES), payload: response });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id, item: response, collectionName: 'exportTemplates' },
                });

                if (showSuccessSnackbar) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            type: NotificationType.SUCCESS,
                            i18nKey: payload.id ? 'exportTemplateUpdated' : 'exportTemplateCreated',
                            i18nValues: { name: response.name },
                        },
                    });
                }

                if (!payload.id && navigate) {
                    navigate(
                        `/data/export-templates/project/${response.project}/template/${response.id}`,
                    );
                }
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(SAVE_EXPORT_TEMPLATES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

export const actions = {
    loadExportTemplates,
    clearExportTemplates,
    cloneExportTemplates,
    clearExportTemplatesActionState,
    deleteExportTemplates,
    saveExportTemplate,
};
